import { Content } from "@/ui";
import { createTitle } from "@/functions/createTitle";
import { simple_pages_helper } from "@/helpers/simple_pages_helper";
import { homepage_tiles_helper } from "@/helpers/homepage_tiles_helper";
import { jobSearch } from "@/functions/jobSearch";

export default function Page({ content }) {
  return (
    <>
      <Content items={content} />
    </>
  );
}

export async function getStaticProps({}) {
  const homepage = simple_pages_helper.find("homepage");
  const homepage_tileGroup = homepage_tiles_helper.fetch();
  const jobs = await jobSearch({ limit: 12 });

  return {
    props: {
      meta: {
        title: createTitle("Home"),
      },
      content: [
        {
          component: "Hero",
          props: {
            img: {
              path: "page.home.component.Hero.img",
            },
          },
        },
        {
          component: "TileGroup",
          props: {
            items: homepage_tileGroup,
          },
        },
        Array.isArray(homepage.carousel_items) && {
          component: "LogoCarousel",
          id: "LogoCarousel",
          props: {
            title: homepage.carousel_title ?? null,
            items: (homepage.carousel_items ?? []).map((i) => ({
              title: i["title"],
              img: i["image"] ?? null,
              href: i["link"] ?? "#",
            })),
            visibleCount: homepage.carousel_items_per_slide ?? 1,
          },
        },
        {
          component: "LatestJobs",
          props: {
            className: "py-5",
            title: { path: "page.home.component.LatestJobs.1.title", placeholder: "Latest Jobs" },
            items: jobs,
            visibleCount: 4,
            button: true,
          },
        },
        Array.isArray(homepage.promo_section) && {
          component: "PromoSection",
          id: "PromoSection",
          props: {
            items: homepage.promo_section.map((i) => ({
              title: i["title"],
              img: i["image"] ?? null,
              href: i["link"] ?? "#",
              description: i["description"],
            })),
          },
        },
      ],
    },
  };
}
